/* BASIC STUFF */
$qudo-yellow: #FCD902;
$qudo-blue: #1867FF;
$qudo-light-gray: #9C9C9C;
$qudo-medium-gray: #656565;
$qudo-dark-gray: #282725;

html {
	/* height: 100vh; */
	/* background-color: #ffffff; */
	background-color: rgb(250, 250, 250);
}

* {
	box-sizing: border-box;
	font-family: Poppins;
	letter-spacing: 0rem !important;
	outline: none;
}

:focus {
	outline: none !important;
}

input {
	-webkit-user-select: text;
	background-color: transparent;
}

a {
	color: #000000;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.w-fit {
	width: fit-content;
}

.with-scroll {
	overflow-y: auto !important;

	&::-webkit-scrollbar {
		width: 12px;
		display: none;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background: #ededed;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
}

.highlight {
	opacity: 1;
}

.faded {
	opacity: 0.5;
}

.form-control {
	height: auto;
}

.default-border-radius {
	border-radius: 0.5rem !important;
}

.enable-click {
	cursor: pointer;
}

.disable-click {
	cursor: initial !important;
}

.disabled {
	pointer-events: none;
	background-color: #b2b2b2 !important;
}

/* CHART STUFF */

.highcharts-button-symbol {
	display: none;
}

.highcharts-button-box {
	display: none;
}

/* BASIC LAYOUT */

#sidebar {
	width: 85px;
	max-width: 85px;

	/* flex: 0 0 7.3%; */
	/* max-width: 7.3%; */

	margin-left: 15px;
	height: 100%;

	background-color: white;
	/* flex: 0 0 100px; */
}

@media only screen and (max-width: 575px) {
	#HomeIcon {
		display: none !important;
	}
}

.meta-box {
	position: fixed !important;
	bottom: 15px;
	left: calc(88px + 15px); //sidebar width + margin (same as bottom)
	/* bottom: -1.5rem; */
	/* left: 8.3%; */

	/* width: 24rem !important; */
	width: 400px !important;

	margin: 0 !important;
	border-radius: 16px;
	z-index: 2;
}

@media only screen and (max-width: 767px) {
	.meta-box {
		position: fixed !important;
		top: 15px;
		/* bottom: 15px; */
		left: 0;
		right: 0;

		margin: auto !important;
		width: 95% !important;
		overflow-y: auto;
	}
}

.meta-header {
	height: 5rem;
	border-bottom: 1px solid #dedede;
	@extend .enable-click;
}

.meta-header-vertical-rule {
	margin-top: -1rem;
	height: 6rem;
	border-right: 1px solid #dedede;
}

.meta-header-button {
	flex: 0 0 49.5%;
	max-width: 49.5%;
	height: 5rem;
	line-height: 5.5rem;
}

.meta-body {
	/* height: 10rem; */
	padding: 1rem;
}

#maincontainer {
	flex: 0 0 calc(100% - 85px);
	max-width: calc(100% - 85px);
	margin-left: 5.5rem;
	overflow: hidden;
}


.left-container {
	flex: 0 0 100%;
	max-width: 100%;
}

.right-container {
	/* flex: 0 0 30%; */
	width: 275px;
	max-width: 275px;
}

.container-fluid {
	width: 100% !important;
	padding: 0 !important;
	position: relative;
}

.container-fluid.active::after {
	content: "";
	position: absolute;
	background-color: rgba(0, 0, 0, 0.32);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

@media only screen and (max-width: 991px) {
	.left-container {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.right-container {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

#navbar {
	display: none !important;
}

@media only screen and (max-width: 767px) {
	#sidebar {
		display: none;
	}

	#navbar {
		display: flex !important;
		position: fixed;
		width: 100%;
		top: 0;
		margin: auto;
		background-color: white;
		height: 4rem;
		z-index: 3;
	}

	#maincontainer {
		margin-top: 4rem;
		flex: 0 0 100%;
		max-width: 100%;
		margin-left: 0%;
		overflow: hidden;
	}
}

#title-box {
	padding-left: 4%;
	display: flex;
	align-items: center;
	margin-top: 1.5rem;
}

#title {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: -1.2px;
	text-align: left;
	color: #282725;
}

#logo {
	width: 70%;
	margin: 0.5rem auto;
}

#logo-navbar {
	width: 4rem;
	/* margin: 0.5rem auto; */
}

.meta-box-outer {
	width: 100%;
	bottom: 15px;
}

.inner-items-sidebar {
	top: 40vh;
	width: 100%;
}

.threedots {
	width: 1.5rem;
	margin-bottom: 0.5rem;
}

.column {
	padding: 15px;
}

.roundcard {
	border-radius: 0.75rem !important;
}

.modal-content {
	overflow: hidden !important;
	border-radius: 1rem !important;
}

.row-default {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

/* WALLET */

.wallet-main-container {
	margin: 0rem 1rem 0rem 0rem !important;
}

.std-button {
	display: inline-block;
	/* margin-top: 20%; */
	/* margin-bottom: 3%; */
	margin-bottom: 0.5rem;
	font-weight: 400;
	width: 100%;
	height: 4rem;
	color: #000000 !important;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	user-select: none;
	border: 1px solid transparent !important;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.std-button-active {
	@extend .std-button;
	background-color: #fbd731 !important;
}

.std-button-active-dark {
	@extend .std-button;
	color: #ffffff !important;
	background-color: rgb(40, 39, 37) !important;
}

#loginwithscatter {
	display: inline-block;
	font-weight: 400;
	width: 100%;
	height: 4rem;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	user-select: none;
	background-color: #fbd731;
	border: 1px solid transparent;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


/* Range slider override */
input[type="range"]::-webkit-slider-thumb {
	background: #fbd731;
}

input[type="range"]::-moz-range-thumb {
	background: #fbd731;
}

input[type="range"]::-ms-thumb {
	background: #fbd731;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: #ebebeb;
}

#receivetext {
	/* font-size: 1vw; */
	font-size: 1rem;
}

/*
@media only screen and (max-width: 991px) {
	#receivetext {
		font-size: 2vw;
	}
}
*/

@media only screen and (max-width: 576px) {
	#receivetext {
		font-size: 3vw;
	}
}

.transactioncard {
	display: inline-block;
	margin-bottom: 2%;
	margin-left: 0% !important;
	font-weight: 400;
	width: 100%;
	height: 4rem;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	/* @extend .enable-click; */
	user-select: none;
	background-color: #ffffff;
	border: 1px solid transparent;
	padding: 0.75rem 1.5rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.arrowimage {
	border: 0.5rem solid #fafafa;
	box-sizing: content-box;
	position: absolute;
	background-color: #fafafa;
}

.arrowimage-nobg {
	position: absolute;
}

.amountgreen {
	font-family: Poppins;
	font-size: 16px;
	margin-bottom: 0%;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: normal;
	text-align: right;
	color: #00cc69;
}

.amountred {
	font-family: Poppins;
	font-size: 16px;
	margin-bottom: 0%;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: normal;
	text-align: right;
	color: #f2544c;
}

.amountyellow {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 200;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.37;
	letter-spacing: normal;
	text-align: left;
	color: #fcd902;
}

.amountgrey {
	font-family: Poppins;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: center;
	color: #656565;
}

.transactiontype {
	margin-bottom: 0%;
	font-family: Open Sans;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.37;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.fromto {
	margin-bottom: 0%;
	font-family: Open Sans;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #b2b2b2;
}

#balancetext {
	font-size: 1.75rem;
	font-weight: lighter;

	.balancedecimals {
		font-size: 1.5rem;
	}
}

@media only screen and (max-width: 767px) {
	#balancetext {
		font-size: 1.5rem;

		.balancedecimals {
			font-size: 1.25rem;
		}
	}
}

#claimpendingbtn {
	display: inline-block;
	margin-top: 3%;
	margin-bottom: 3%;
	font-weight: 400;
	width: 100%;
	//height: 4.5rem;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	//user-select: none;
	background-color: #fbd731;
	border: 1px solid transparent;
	//padding: 0rem 0.75rem 0rem 1rem;
	font-size: 1rem;
	//line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.scroll-25 {
	max-height: 25vh;
	overflow-y: auto;
}

.scroll-50-no-scrollbar {
	max-height: 50vh;
	overflow-y: auto;
	//min-height: 30vh;

	&::-webkit-scrollbar {
		width: 12px;
		display: none;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background: #ededed;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
}

.scroll-50 {
	max-height: 50vh;
	overflow-y: auto;
}

.scroll-65 {
	max-height: 65vh;
	overflow-y: auto;
}

.scroll-75 {
	max-height: 75vh;
	overflow-y: auto;
}

.scroll-70-no-scrollbar {
	max-height: 70vh;
	overflow-y: auto;
	min-height: 30vh;

	&::-webkit-scrollbar {
		width: 12px;
		display: none;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		-webkit-border-radius: 10px;
		border-radius: 10px;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		-webkit-border-radius: 10px;
		border-radius: 10px;
		background: #ededed;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
}

.scroll-95 {
	max-height: 95vh;
	overflow-y: auto;
}

.scroll-100 {
	max-height: 100vh;
	overflow-y: auto;
}

.separator {
	content: "";
	display: inline-block;
	background: #282725;
	height: 4.5rem;
	margin-top: -0.8rem;
	width: 1px;
}

#claimtext {
	opacity: 0.9;
	color: #282725;
}

#qudoinfobtn {
	display: inline-block;
	margin-top: 2%;
	margin-bottom: 2%;
	width: 100%;
	height: 4rem;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	user-select: none;
	background-color: transparent;
	border: 1px solid #e3e3e3;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* COMMUNITY */

#community-welcome {
	font-family: Poppins;
	font-size: 2rem;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: 0rem;
	/* text-align: left; */
	color: #282725;
}

@media only screen and (max-width: 767px) {
	#community-welcome {
		font-size: 1.75rem;
	}

	#community-welcome-message {
		font-size: 0.9rem;
	}
}

.community-background {
	>div:nth-child(even) {
		background-color: #ededed;
	}
}

#qudo-now {
	z-index: 1;
	position: fixed;
	overflow: auto;
	right: 0;
	width: 100%;
	background-color: #282725;
	height: 100%;
	padding: 1.6rem;
}

#qudo-now-title {
	font-size: 1.5rem;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: -1.2px;
	text-align: left;
	color: #fff;
}

.qudo-community-text-sm {
	font-family: Open Sans;
	font-size: 0.9rem;
	bottom: 2rem;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	text-align: left;
	color: #282725;
}

.know-more {
	margin-top: 1.5rem;
	font-size: 0.8rem;
}

.yellow-text {
	color: #fbd731 !important;
}

.qudo-now-total {
	width: fit-content;

	.qudo-now-total-title {
		font-weight: 500;
		width: fit-content;
		text-wrap: nowrap;
	}

	.qudo-now-text-sm {
		font-size: 1.2rem;
		font-weight: 600;
		line-height: 1.2;
	}
}

.qudo-now-text {
	font-size: 1rem;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	/* text-align: left; */
	color: #ffffff;
}

.qudo-now-text-xs {
	font-size: 0.7rem;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	/* text-align: left; */
	color: #ffffff;
}

.qudo-now-text-sm {
	font-size: 1.5rem;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	/* text-align: left; */
	color: #ffffff;

	span {
		font-size: 0.8rem;
		margin-left: 5px;
	}
}

.qudo-now-text-bg {
	font-size: 1.5rem;
	/* margin-top: 2.6rem; */
	margin-bottom: 0.5rem;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	/* text-align: left; */
	color: #ffffff;
}

.qudo-now-text-huge {
	font-size: 2rem;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: -2.4px;
	/* text-align: left; */
	color: #ffffff;

	span {
		font-size: 0.8rem;
		margin-left: 5px;
	}
}

.qudo-now-block {
	font-size: 0.9rem !important;
	font-weight: 500;
	align-self: center;
}

@media only screen and (max-width: 1232px) {
	.qudo-now-block {
		font-size: 0.8rem !important;
	}

	.qudo-now-total {
		.qudo-now-total-title {
			font-size: 0.5rem;
		}

		.qudo-now-text-sm {
			font-size: 1rem;
		}
	}

	.qudo-now-text-huge {
		align-self: center;
		font-size: 2rem;

		.qudo-now-text {
			font-size: 0.6rem;
		}
	}
}

@media only screen and (max-width: 1106px) {
	#qudo-now-title {
		font-size: 1.1rem;
		margin-bottom: 0px;
	}

	.qudo-now-text-sm {
		font-size: 1.3rem;
	}

	.qudo-now-block {
		padding: 5px !important;
		font-size: 0.8rem !important;
	}

	.qudo-now-text-huge {
		font-size: 2rem;
	}
}

@media only screen and (max-width: 991px) {
	#telos-now-mobile-padding {
		padding-right: 0px !important;
	}

	#qudo-now {
		padding-top: 0.75rem;
		padding-bottom: 1.5rem;
		position: relative;
	}

	#qudo-now-title {
		font-size: 1.25rem;
		margin-bottom: 0px;
	}

	.qudo-now-block {
		font-size: 0.8rem !important;
	}

	.qudo-now-text-huge {
		font-size: 2rem !important;

		.qudo-now-text {
			font-size: 0.5rem;
		}
	}
}

@media only screen and (max-width: 684px) {
	.qudo-now-text-huge {
		font-size: 1.4rem !important;
	}

	.qudo-now-text-sm {
		font-size: 1.3rem;
	}

}

@media only screen and (max-width: 575px) {
	.qudo-now-text-huge {
		font-size: 1.2rem !important;
	}

	.qudo-now-text-sm {
		font-size: 1.2rem;
	}

	.qudo-now-block {
		font-size: 0.7rem !important;
	}

}

@media only screen and (max-width: 450px) {
	#qudo-now-title {
		font-size: 1rem;
	}
}

#qudo-now-chart {
	margin-left: -2.5rem;
}

#qudo-now-hr {
	background-color: #fafafa;
	margin-left: -1.8rem;
	margin-right: -1rem;
	/* margin-left: -2.5rem;
  margin-right: -1rem;
  margin-top: 0.3rem;
  margin-bottom: 1.5rem; */
}

#qudo-now-dropdown {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	position: absolute;
	z-index: 30;
	bottom: 10px;
	right: 10px;
	/* margin-top: 1%;
  margin-bottom: 1%; */
	width: 80%;
	color: white;
	text-align: left;
	@extend .enable-click;
	background-color: transparent;
	border: 1px solid #e3e3e3;
	/* padding: 1.375rem 1.75rem !important; */
	padding: 0rem 1rem;
	line-height: 1.5;
	/* border-radius: 0.5rem; */
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#header {
	/* background-color: #fbd731; */
	background-color: #fcc404;
	height: 25rem;
	padding: 3rem 0rem 1rem 5rem;
	overflow: hidden;
}

#biglogo {
	right: -0.4rem;
	bottom: -8rem;
}

@media only screen and (max-width: 991px) {
	#biglogo {
		top: auto;
		bottom: -5rem;
	}
}

@media only screen and (max-width: 767px) {
	#biglogo {
		top: auto;
		bottom: -2rem;
	}
}

@media only screen and (max-width: 767px) {
	#header {
		margin-left: -3rem;
		/* height: 35rem; */
	}

	#biglogo {
		top: auto;
		bottom: 0rem;
	}
}

.main-hr {
	margin-left: -2.8rem;

	margin-top: 0;
	margin-bottom: 0;

	border-top: 2px solid rgba(0, 0, 0, 0.25);
}

.main-inner-title {
	margin-top: 0.3rem;
	margin-right: auto;
	font-family: Poppins;
	font-size: 1.5rem;
	font-weight: 500;
	letter-spacing: -1.2px;
	text-align: left;
	color: #282725;
}

.main-inner-subtitle {
	margin-left: 0.2rem;
	margin-top: -0.8rem;
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: -0.8px;
	text-align: left;
	color: #656565;
}

#tw-text-right {
	padding-left: -5rem;
	padding-top: 1rem;
	/* margin-right: 1rem; */
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: -0.8px;
	text-align: left;
	color: #656565;
}

.game-website-tw-image {
	// width: 97.5%;
	width: 100%;
	height: 280px;
	object-fit: cover;
	border-radius: 0.5rem;
	margin: auto;
}

.mini-game-website-tw-image {
	// width: 97.5%;
	width: 100%;
	height: 70px;
	object-fit: cover;
	border-radius: 0;
	margin: auto;
}

.game-website-tw-comingsoon {
	position: absolute;
	width: 100%;
	height: 24.1rem;
	right: 0;
	top: -25%;
}

.tw-image-container {
	position: relative;
	display: inline-block;
	overflow: hidden;
	// width: 97.5%;
	width: 100%;
}

.tw-image-container::after {
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	margin-top: -15rem;
	margin-left: auto;
	margin-right: auto;
	height: 15rem;
	// max-width: 97.5%;
	@extend .default-border-radius;
	content: "";
}

.mini-tw-image-container {
	position: relative;
	display: inline-block;
	overflow: hidden;
	// width: 97.5%;
	width: 100%;
}

.mini-tw-image-container::after {
	display: block;
	position: relative;
	margin-top: -70px;
	margin-left: auto;
	margin-right: auto;
	height: 75px;
	// max-width: 97.5%;
	content: "";
}

.swiper-button-next-custom {
	background-image: url("./images/definitive/icn-slider-forward.png");
	height: 40px;
	width: 40px;
	position: absolute;
	top: 50%;
	right: 25px;
}

.swiper-overflow-hidden {
	overflow: hidden;
}

.swiper-button-prev-custom {
	background-image: url("./images/definitive/icn-slider-back.png");
	height: 40px;
	width: 40px;
	position: absolute;
	top: 50%;
	left: 15px;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
	@extend .enable-click;
}

@media only screen and (max-width: 767px) {

	.swiper-button-prev-custom,
	.swiper-button-next-custom {
		display: none;
	}
}

/*
@media only screen and (min-width: 1600px) {
	.gl-swiper-container {
		margin: 0 10%;
	}
}
*/

.game-website-gl-image {
	max-width: 90%;
	height: 11.2rem;
	object-fit: cover;
	border-radius: 0.5rem;
	/* padding: 0rem 1rem; */
}


.game-website-gl-comingsoon {
	position: absolute;
	width: 150%;
	height: 16.5rem;
	right: -25%;
	top: 3%;
	overflow: hidden;
}

.gl-image-container {
	position: relative;
	overflow: hidden;
	display: inline-block;
}

.gl-image-container::after {
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	margin-top: -11.2rem;
	height: 11.2rem;
	max-width: 90%;
	@extend .default-border-radius;
	content: "";
}

/* 
.carousel-control-next-icon {
  margin-left: 4rem;
  background-image: url(./images/definitive/icn-chevron-right.png) !important;
}

.carousel-control-prev-icon {
  margin-right: 4rem;
  background-image: url(./images/definitive/icn-chevron-right.png) !important;
} */

.tw-slider {
	margin-top: 0.9rem;
	margin-right: auto;
}

.slick-prev,
.slick-next {
	margin-right: 0rem;
	margin-top: -0.8rem;
}

.slick-prev:before,
.slick-next:before {
	content: "" !important;
}

.main-inner-container {
	margin: auto;
	padding: 1rem 3rem;
}

@media only screen and (max-width: 576px) {
	.main-inner-container {
		padding: 0.5rem 2rem;
	}

	#tw-container {
		padding: 2rem 2rem;
	}
}

/* Bottom right text */
.games-textblock {
	position: absolute;
	bottom: 10%;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
	text-wrap: wrap;
	width: 170px;
}

.games-text-bg {
	position: relative;
	font-family: Poppins;
	font-size: 15px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: -0.8px;
	text-align: left;
	color: #ffffff;
	white-space: inherit;
	word-wrap: normal;
}

.games-text-sm {
	font-family: Poppins;
	font-size: 12px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.gl-slider {
	margin-top: 1rem;
	margin-right: auto;
}

.gd-slider {
	margin-top: 1rem;
	margin-right: auto;
	align-content: space-between;
}

.gd-card {
	/* height: 10rem; */
	min-height: 10rem;
	object-fit: cover;
	border-radius: 10px;
	max-width: 400px;
}

@media only screen and (max-width: 576px) {
	#gd-container {
		padding: 2rem 2rem;
	}
}

.gd-list-gamedev-name {
	font-size: 1rem;
	margin-right: -0.4rem;
	margin-top: -1rem;
	font-weight: bold;
	letter-spacing: -0.8px;
	color: #282725;
}

.gd-list-games-count {
	font-size: 13px;
	margin-right: -0.4rem;
	margin-top: -0.2rem;
	font-weight: normal;
	line-height: 1.48;
	color: #282725;
}

.gd-images-box {
	margin-right: -0.5rem;
}

#gd-list-games-image {
	margin-top: 0.8rem;
	margin-left: 0.2rem;
	width: 3.3rem;
	@extend .default-border-radius;
	height: 4.11rem;
	object-fit: cover;
	display: inline-block;
}

.gd-list-thumbnail {
	/* height: 3rem; */
	/* margin-left: -0.45rem; */
	max-width: 100%;
	max-height: 5rem;
}

.top-players-card {
	/* display: inline-block; */
	/* max-width: 99%; */
	padding: 1rem;
	/* height: 5rem; */
	min-height: 5rem;
	margin-bottom: 1rem;
	color: #000000;
	background-color: transparent;
	border: 1px solid #e3e3e3;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&.custom {
		padding: 0.5rem;
		min-height: 4rem;
		margin-bottom: 0.5rem;
	}

	.points {
		font-family: Poppins;
		font-size: 0.75rem;
		margin-bottom: -0.5rem;
		letter-spacing: -0.8px;
		text-align: left;
		color: #b2b2b2;
		white-space: nowrap;

		@media only screen and (max-width: 575px) {
			font-size: 0.7rem !important;
			margin-bottom: 0.2rem;
		}
	}

	.numbers {
		font-family: Poppins;
		font-size: 1rem;
		margin-bottom: -0.5rem;
		letter-spacing: -0.8px;
		text-align: left;
		color: #00cc69;

		@media only screen and (max-width: 575px) {
			font-size: 0.7rem !important;
			margin-bottom: 0;
		}
	}

	.username {
		@media only screen and (max-width: 575px) {
			font-size: 0.7rem !important;
		}
	}
}

.dropdown-highscores {
	background-color: #fcd902 !important;
	padding: 1rem;
}

/*
.tp-container {
	margin-top: 2.2rem;
}
*/

@media only screen and (max-width: 576px) {
	.tp-container {
		margin-right: 0;
	}
}

.tp-list {
	margin-top: 1rem;
}

.top-players-trophy {
	/* margin-left: 1.5rem; */
	/* padding-left: 1.5rem !important; */
	padding-right: 1.5rem;
}

#top-players-info-top {
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: -0.5rem;
	letter-spacing: -0.8px;
	text-align: left;
	color: #282725;
}

#top-players-info-bottom {
	font-family: Poppins;
	font-size: 1rem;
	font-weight: bold;
	letter-spacing: -0.8px;
	text-align: left;
	color: #282725;
}

#top-players-score {
	font-family: Poppins;
	font-size: 1rem;
	letter-spacing: -0.8px;
	text-align: left;
	color: #282725;
}

#top-players-games {
	max-height: 3rem;
	top: 50%;
	width: 3rem;
	transform: translateY(-50%);
	/* padding-right: 1rem; */
}

#trg-dropdown,
#trg-dropdown-white,
#trg-dropdown-grey {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	bottom: 10px;
	/* right: 10px; */
	color: #000000;
	text-align: left;
	@extend .enable-click;
	background-color: transparent;
	border: 1px solid #000000;
	/* padding: 1.375rem 1.75rem 1.375rem 0.5rem !important; */
	/* padding: 0rem 1rem; */
	padding: 0.5rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#trg-dropdown-white {
	color: #ffffff;
	border: 1px solid #ffffff;
}

#trg-dropdown-grey {
	color: #7d7d7d;
	border-bottom: 1px solid #7d7d7d;
}

.game-website-trg-image {
	width: 100%;
	height: 22rem;
	object-fit: cover;
	border-radius: 0.5rem;
	margin: auto;
}

.trg-image-container {
	position: relative;
	display: block;
	margin: auto;
	width: 50%;
}

@media only screen and (max-width: 991px) {
	.trg-image-container {
		width: 75%;
	}
}

@media only screen and (max-width: 576px) {
	.trg-image-container {
		width: 100%;
	}
}

.trg-image-container::after {
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	margin-top: -35rem;
	margin-left: auto;
	margin-right: auto;
	height: 35rem;
	max-width: 100%;
	@extend .default-border-radius;
	content: "";
}

.trg-games-icon {
	position: absolute;
	top: 2%;
	right: 0;
	left: 0;
	margin-right: auto !important;
	margin-left: auto !important;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
}

.trg-text-sm {
	font-size: 0.8rem;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.trg-games-text-container {
	position: absolute;
	width: 50%;
	top: 30%;
	right: 0;
	left: 0;
	margin-right: auto !important;
	margin-left: auto !important;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
}

.trg-games-bottom {
	position: absolute;
	bottom: 10%;
	left: 28%;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
}

@media only screen and (max-width: 575px) {
	.trg-games-text-container {
		width: 100%;
	}

	.trg-games-bottom {
		left: 0;
	}
}

.ref-container {
	margin: auto;
	padding: 4rem 4rem 2.5rem 4rem;
}

#sh-list-title {
	padding: 0rem 3.2rem;
	/* padding-top: 1.9rem; */
	margin-left: -3.5rem;
}

.sh-list-subtitle {
	margin-left: -0.8rem;
	margin-top: -0.2rem;
	margin-bottom: 0.8rem;
}

#sh-container {
	margin: auto;
	background-color: #fbd731;
	padding: 4rem 4rem 2.5rem 4rem;
}

.sh-avatar-outer {
	/* margin-right: -0.8rem; */
	padding-left: 0px !important;
}

@media only screen and (max-width: 576px) {
	.sh-list-subtitle {
		margin-left: 1.5rem;
		margin-top: -0.2rem;
		margin-bottom: 0.8rem;
	}
}

.sh-avatar {
	max-width: 3rem;
}

.sh-ranking {
	margin-bottom: -0.5rem;
}

.top-stakeholders-card {
	/* display: inline-block; */
	/* width: 23.3rem; */
	/* max-width: 24.5rem; */
	/* height: 5rem; */
	min-height: 5rem;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	align-items: center;
	background-color: transparent;
	/* border: 1px solid #dedede; */
	/* border-bottom: 1px solid #dedede; */
	display: flex;
	align-content: center;
	position: relative;
	/* margin-left: 0.6rem; */
	padding: 1rem;
	font-size: 1rem;
	line-height: 1.5;
	/* border-radius: 0.5rem; */
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

#lb-container {
	margin: auto;
	padding: 4rem;
	background-color: #282725;
}

@media only screen and (max-width: 576px) {
	#lb-container {
		padding: 4rem 2.5rem;
	}
}

#lb-inner-container {
	background-color: #ffffff;
	border-radius: 0.5rem;
}

#lb-table-head {
	border: 0;
}

#lb-title-box {
	/* margin-bottom: 3.2rem; */
	margin-right: -1rem;
	margin-left: -1rem;
}

.lb-title {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: -1.2px;
	text-align: left;
	color: #ffffff;
}

.lb-date {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	color: #ffffff;
	/* text-align: right; */
	float: left;
}

@media only screen and (min-width: 576px) {
	.lb-date {
		float: right;
	}
}

.lb-headers-box {
	margin-bottom: -3rem;
}

.lb-username-header {
	font-family: Poppins;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #282725;
}

.lb-username {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #282725;
}

.lb-activity {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: right;
	color: #282725;
}

.lb-activity-header {
	font-family: Poppins;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: right;
	color: #282725;
}

.lb-total-amount-title {
	margin-top: 2rem;
	font-family: Poppins;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: right;
	color: #282725;
}

.lb-total-amount {
	font-family: Poppins;
	font-size: 3.5rem;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: -3.6px;
	text-align: center;
	color: #000000;
}

#footer-container {
	background-color: #282725;
	padding: 4rem;
	margin-top: 0% !important;

	/* padding: 2.8rem 5rem;
  margin-left: -2.4rem; */
}

@media only screen and (max-width: 576px) {
	#footer-container {
		margin-left: -1.1rem;
		margin-right: -1.1rem;
	}
}

#forgot-password-text {
	margin-top: 6%;
	font-size: 0.9rem;
	color: #212529;
}

.input-field-custom {
	height: 4rem !important;
	border-radius: 8px !important;
	border: 1px solid #e7e7e7 !important;
}

.input-field-custom-tall {
	@extend .input-field-custom;
	height: 20rem !important;
}

.dropdown-field-with-title {
	height: 4rem !important;
	border-radius: 8px !important;
	border: 1px solid #e7e7e7 !important;
}

/* .edit-field {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  display: inline-block;
  margin-top: 1%;
  margin-bottom: 1%;
  width: 100%;
  height: 4.5rem !important;
  color: #000000;
  text-align: left;
  vertical-align: middle;
  @extend .enable-click;
  background-color: transparent !important;
  border: 1px solid #e3e3e3 !important;
  padding: 1.375rem 1.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

.login-main-text {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	font-family: Poppins;
	font-size: 1.5rem;
	font-weight: 500;
	letter-spacing: -1.2px;
	text-align: left;
	color: #282725;
}

.login-second-text {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: -1.2px;
	text-align: left;
	color: #8f8f8f;
}

.telegram-game-modal-text {
	margin-top: 2.5rem;
	margin-bottom: 2.5rem;
	font-family: Poppins;
	font-size: 1.3rem;
	font-weight: 500;
	text-align: left;
	color: #282725;

	span {
		color: #007bff;
		font-weight: 600;
		cursor: pointer;
	}
}

.form-submit-btn {
	display: inline-block;
	margin-top: 10%;
	margin-bottom: 3%;
	font-weight: 400;
	width: 100%;
	height: 4rem;
	color: #000000 !important;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	user-select: none;
	background-color: #fbd731 !important;
	border: 1px solid transparent !important;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-logout-btn {
	display: inline-block;
	margin: 0rem 2rem;
	font-weight: 400;
	width: 100%;
	height: 4rem;
	color: #000000 !important;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	user-select: none;
	background-color: #fbd731 !important;
	border: 1px solid transparent !important;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* ACTIVITY */

/*
.activity-main-container {
	margin: 0rem 1rem 0rem 0rem !important;
}
*/

.lastplayed-slider {
	margin: auto;
	overflow: hidden;
}

.game-website-lastplayed-image {
	width: 100%;
	height: 13rem;
	object-fit: cover;
	border-radius: 0.5rem;
	margin: auto;
}

.lastplayed-image-container {
	position: relative;
	display: inline-block;
	width: 100%;
}

.lastplayed-image-container::after {
	display: block;
	position: relative;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	margin-top: -13rem;
	margin-left: auto;
	margin-right: auto;
	height: 13rem;
	max-width: 100%;
	@extend .default-border-radius;
	content: "";
}

/* .lastplayed-image {
  border-radius: 0.5rem;
  border: 1px solid red;
  height: 20%;
  max-width: 100%;
  margin-bottom: 1rem;
}

.lastplayed-image::after {
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
  height: 20vh;
  border-radius: 0.5rem;
  content: '';
}
*/

.lastplayed-textblock {
	position: absolute;
	bottom: 8%;
	left: 2%;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
}

.lastplayed-text-bg {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: -0.8px;
	text-align: left;
	color: #ffffff;
}

.lastplayed-text-sm {
	font-family: Poppins;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.achievements-container {
	margin-left: 2rem;
	margin-right: 2rem;
}

@media only screen and (max-width: 767px) {
	.achievements-container {
		margin-left: 0rem;
		margin-right: 0rem;
	}
}

.achievements-ach-card {
	display: inline-block;
	font-weight: 400;
	/* height: 5.2rem; */
	/* min-height: 75px; */
	margin-bottom: 0.5rem;
	color: #000000 !important;
	text-align: left;
	vertical-align: middle;
	/* @extend .enable-click; */
	user-select: none;
	background-color: #ffffff !important;
	border: 1px solid transparent !important;
	padding: 1.375rem 1rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.achievements-ach-name {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.achievements-amount {
	font-family: Poppins;
	font-size: 24px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: right;
	color: #00cc69;
}

.achievements-game-name {
	font-family: Poppins;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;
	color: #b2b2b2;
}

.network-rew-title {
	font-family: Poppins;
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: -1.2px;
	text-align: center;
	color: #282725;
}

.network-rew-sm {
	margin-bottom: 1rem;
	font-family: Poppins;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: center;
	color: #00cc69;
}

.network-rew-bg {
	font-family: Poppins;
	font-size: 64px;
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #00cc69;
}

/*
#network-rew-chart {
	padding-left: 3rem;
}
*/

.activity-vertical-rule {
	position: absolute;
	top: 0;
	left: 33.3%;
	height: 95vh;
	border-right: 1px solid #dedede;
}

@media only screen and (max-width: 991px) {
	.activity-vertical-rule {
		display: none;
	}
}

/* GAME ACTIVITY */

#title-box-gamedev {
	margin-left: 1.5rem;
	display: flex;
	align-items: center;
	margin-top: 1.5rem;
}

.gamedevact-main-container {
	max-width: 90% !important;
	margin: 0 3.5rem !important;
	/* @extend .enable-click; */
	display: flex;
	flex-direction: row;

	.gamedevact-add-btn {
		align-self: flex-end;
		margin: 15px;

		.std-button-active {
			height: auto;
			width: 100%;
			padding: 0.5rem;
			margin: 0;
		}
	}

	.gamedevact-component-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		.gamedevact-nogames-video {
			width: auto;
			height: auto;

			.react-player {
				padding-top: 56.25%;
				position: relative;
			}

			.react-player>div {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.gamedevact-nogames-btn {
			width: 200px;
		}
	}
}

#addapp {
	@extend .enable-click;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: white;
	z-index: 999;

	&:hover {
		color: #282725;
	}
}

#addgame {
	@extend .enable-click;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #282725;
}

.gamedevact-slider {
	margin-right: auto;
	width: 80%;
}

#resources-pay-banner {
	width: 80%;
	display: flex;
	flex-direction: column;
	text-align: center;
}

@media only screen and (max-width: 767px) {
	.gamedevact-main-container {
		max-width: 100% !important;
		/* margin: 1rem !important; */
		margin: auto !important;

		.gamedevact-component-container {
			justify-content: center;
			margin: 0 1.5rem;
		}
	}

	.gamedevact-slider {
		width: 95%;
	}

	.gamedevact-game-card {
		margin: 5px 25px;
	}

	.gamedevact-application-card {
		margin: 5px 25px;
	}
}

.gamedevact-game-card {
	height: 400px;
	width: 280px;
	margin-top: 15px;
	margin-bottom: 15px;
	/* max-width: 95%; */

	background-color: #282725;
	border-radius: 16px;
	overflow: hidden;

	.gamedevact-text-bg-game {
		font-family: Poppins;
		font-size: 2rem;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		margin-top: 3vh;
		margin-bottom: 1vh;
		line-height: 1;
		color: #ffffff;
	}

	.gamedevact-image-container {
		position: relative;
		display: inline-block;
		width: 100%;
	}

	.gamedevact-image-container::after {
		display: block;
		position: relative;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 60%, #282725);
		margin-top: -50vh;
		margin-left: auto;
		margin-right: auto;
		height: 50vh;
		max-width: 100%;
		@extend .default-border-radius;
		content: "";
	}
}

.gamedevact-application-card {
	height: 10vh;
	margin-top: 15px;
	margin-bottom: 15px;
	max-width: 300px;

	background-color: #282725;
	border-radius: 0.5rem;
	overflow: hidden;
}

.game-website-gamedevact-image {
	width: 100%;
	height: 32vh;
	object-fit: cover;
	border-radius: 16px;
	margin-top: -10px;
}

.gamedevact-image-application-website {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 0.5rem;
	margin-top: -1rem;
}

.gamedevact-textblock-game {
	position: absolute;
	bottom: 2%;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
}

.gamedevact-textblock-application {
	position: fixed;
	bottom: 20px;
	color: white;
	padding-left: 20px;
	padding-right: 20px;
}


.gamedevact-approval-card {
	top: 0;
	right: 0;
	left: 0;
}

.gamedevact-text-bg-application {
	font-family: Poppins;
	font-size: 20px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	margin-top: 0vh;
	margin-bottom: 0vh;
	line-height: 1;
	letter-spacing: -1.8px !important;
	/* text-align: left; */
	color: #ffffff;
}

.gamedevact-text-sm {
	font-family: Poppins;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.gamedevact-link {
	font-family: Poppins;
	margin-top: 7vh;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.progress-bar {
	@extend .default-border-radius;
	background-color: #fcd902 !important;
}

.gamedevact-progress-label {
	font-family: Poppins;
	font-size: 11px;
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #b2b2b2;
}

.gamedevact-progress-label-values {
	font-family: Poppins;
	font-size: 11px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #ffffff;
}

.gamedevact-edit-screen-container {
	/* height: 100%; */
	/* overflow-y: scroll; */
	margin: 0rem !important;
}

.section {
	line-height: 1;
	text-align: left;
	color: #000000;
	font-family: Poppins;

	&-huge {
		@extend .section;
		font-size: 64px;
	}

	&-title {
		@extend .section;
		font-size: 16px;
		font-weight: 500;
	}

	&-subtitle {
		@extend .section;
		margin: 1rem auto 0.25rem;
		font-size: 13px;
		line-height: 1.48;
		color: #b2b2b2;
		font-weight: 500;
	}
}


.edit-game-description {
	height: 20rem !important;
	resize: vertical;
}

.edit-screen-arrow-container {
	/* height: 2rem; */
	/* width: auto; */
	padding: 5rem;
	background-color: white;
}

.edit-screen-left-column {
	/* height: 100vh; */
	padding-top: 5rem;
	background-color: white;
}

.edit-screen-image-container {
	/* position: absolute; */
	/* margin-top: 10vh; */
	/* right: 0;
  margin-right: auto;
  left: 0;
  margin-left: auto; */
	display: flex;
	justify-content: center;
	overflow: hidden;
}

.edit-screen-image {
	width: 100%;
	@extend .default-border-radius;
	z-index: 0;
}

.edit-screen-gradient {
	position: absolute;
	left: 0;
	margin-left: auto;
	right: 0;
	margin-right: auto;
	height: 100%;
	@extend .default-border-radius;
	/* width: 92%; */
	width: 100%;
	/* bottom: -30%; */
	z-index: 0;
}

@media only screen and (max-width: 767px) {
	.edit-screen-image {
		max-width: 55%;
		min-width: 250px;
		min-height: 250px;
	}

	.edit-screen-gradient {
		max-width: 55%;
		min-width: 250px;
		min-height: 250px;
	}
}

.edit-screen-avatar-box {
	margin: 15px auto;
	max-width: 75% !important;
}

.edit-screen-upload-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 8rem;
	border: 2px dotted #b2b2b2;
}

.editcoverbtn {
	@extend .enable-click;
	position: absolute;
	bottom: 15px;
	right: 0;
	left: 0;
	margin-right: auto;
	margin-left: auto;
	display: inline-block;
	font-weight: 400;
	min-width: 175px;
	max-width: 50%;
	height: 4rem;
	color: #000000;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: #fbd731;
	border: 1px solid transparent;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.editcovertext {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: center;
	color: #282725;
}

.edit-screen-right-column {
	/* height: 100vh; */
	padding-top: 5rem;
}

#achievementsFilterField {
	font-family: Poppins;
	font-size: 13px !important;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	display: flex;
	/* margin-top: 1%;
  margin-bottom: 1%; */
	width: 100%;
	height: 3rem !important;
	color: rgba(40, 39, 37, 0.5);
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	background-color: transparent !important;
	border-radius: 8px !important;
	border: solid 1px #ededed !important;
	/* padding: 1.375rem 0rem 1.375rem 1rem !important; */
	font-size: 1rem;
	line-height: 1.5;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.achievementcard {
	font-family: Poppins;
	font-size: 13px !important;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	display: flex;
	width: 100%;
	color: rgba(40, 39, 37, 0.5);
	/* margin-top: 0.625rem; */
	margin-bottom: 0.625rem;
	padding: 0.75rem;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	background-color: transparent;
	border-radius: 8px !important;
	border: solid 1px #707070;
	font-size: 1rem;
	line-height: 1.5;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;

	&-border-faded {
		@extend .achievementcard;
		border: none;
		border-radius: 10px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		/* margin-top: 0.5rem; */
		margin-bottom: 0.5rem;
	}
}

@media only screen and (min-width: 1024px) {
	.achievementcard {
		width: 75%;
	}
}

.edit-screen-menu {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: -0.8px;
	text-align: left;
	color: #282725;

	&.sub-menu {
		font-size: 14px
	}
}

.edit-screen-menu-disabled {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 2.25;
	letter-spacing: -0.8px;
	text-align: left;
	color: #b2b2b2;

	&.sub-menu {
		font-size: 14px;
	}
}

#edit-screen-text-input-outer {
	overflow: scroll;
	border: 1px solid #ededed;
	padding-top: 2rem;
	padding-bottom: 4rem;
	border-radius: 8px 8px 0 0;
}

.edit-screen-input {
	width: 100%;
	height: 5vh;
	border-color: transparent;
}

.editgamebtn {
	margin-right: auto;
	margin-left: auto;
	margin-top: 5px;
	display: inline-block;
	font-weight: 400;
	max-width: 100%;
	height: 4rem;
	color: #000000;
	text-align: center;
	vertical-align: middle;
	@extend .enable-click;
	user-select: none;
	background-color: #fbd731;
	border: 1px solid transparent;
	padding: 1.375rem 1.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&.custom-height {
		padding: 1rem;
		line-height: 1rem;
		height: 3rem;
		margin-top: 2px;
	}
}

.edit-screen-input::placeholder {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.edit-screen-input:focus {
	outline: none !important;
	border: 1px solid #fbd731;
	box-shadow: 0 0 10px #fbd731;
}

.edit-screen-text-area {
	width: 100%;
	font-family: Open Sans;
	overflow-y: hidden;
	border-color: transparent;
	font-size: 14px;
	line-height: 1.5;
	word-wrap: break-word;
	white-space: pre-line;
}

.edit-screen-text-area:focus {
	outline: none !important;
	border: 1px solid #fbd731;
	box-shadow: 0 0 10px #fbd731;
}

.edit-screen-text-area::placeholder {
	text-align: left;
	color: #000000;
}

.no-edit {
	background-color: #fafafa !important;
}

.edit-screen-text-save-buttons {
	/* border: 1px solid #ededed; */
	/* @extend .default-border-radius; */
	border-bottom: 1px solid #ededed;
	border-right: 1px solid #ededed;
	border-left: 1px solid #ededed;
	border-radius: 0 0 8px 8px;
}

.edit-screen-edit-button {
	height: 3.5rem;
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: center;
	color: #656565;
}

/*
.edit-screen-upgrade-container {
	padding-top: 2rem;
}
*/

.edit-screen-stakes-sm {
	font-family: Poppins;
	font-size: 13px;
	margin-top: 1vh;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.edit-screen-stakes-big {
	font-family: Poppins;
	margin-top: 2vh;
	font-size: 2.8vw;
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	line-height: 0.75;
	letter-spacing: -2.4px;
	text-align: left;
	color: #b2b2b2;
	@extend .numbered-range-input;
}

.edit-screen-stakes-big-input {
	@extend .edit-screen-stakes-big;
	width: 100%;
	margin-top: 0;
	margin-bottom: -1.5vh;
}

.numbered-range-input {
	width: 100%;
	border: none;
	font-size: 1.75rem !important;

	&.big-number {
		font-size: 3rem !important;
		font-weight: normal;
	}
}

/* Chrome, Safari, Edge, Opera */
.numbered-range-input::-webkit-outer-spin-button,
.numbered-range-input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.numbered-range-input[type=number] {
	-moz-appearance: textfield;
}

.numbered-range-input:invalid {
	box-shadow: none;
}

.text-area-input {
	padding: 1.75rem !important;
}

.edit-screen-stakes-faded {
	font-family: Poppins;
	font-size: 11px;
	font-weight: 200;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.75;
	letter-spacing: normal;
	text-align: left;
	color: #b2b2b2;
}

.edit-profile-left-column {
	padding: 2rem 30px 30px 30px !important;
}

.edit-profile-right-column {
	padding-top: 2rem;
}

.edit-profile-personal-info-box {
	/* margin-top: 2vh; */
	margin-left: 5%;
}

.edit-profile-password-box {
	margin-top: 3rem;
	margin-bottom: 3rem;
	margin-left: 5%;
}

.edit-profile-email-settings-box {
	margin-bottom: 3rem;
	margin-left: 5%;
}

.delete-account-message-to-support {
	margin-bottom: 3rem;
	margin-left: 5%;
}

.delete-account-message-to-support a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}


@media only screen and (max-width: 767px) {
	.edit-profile-personal-info-box {
		margin-left: 0;
	}

	.edit-profile-password-box {
		margin-left: 0;
	}

	.edit-profile-email-settings-box {
		margin-left: 0;
	}
}

.taller-field {
	height: 4rem !important;
	margin-top: 1%;
	margin-bottom: 1%;
}

.edit-field {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	display: inline-block;
	width: 100%;
	margin-top: 1%;
	margin-bottom: 1%;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	background-color: transparent !important;
	border: 1px solid #e3e3e3 !important;
	padding: 1.375rem 1.75rem !important;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.edit-field-yellow {
	@extend .edit-field;
	//background-color: #fcd902 !important;
	border: 1px solid #fcd902 !important;
}

#edit-profile-filter-divider {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #656565;
}

#edit-profile-filter-items {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #282725;
}

/* .input-field-custom {
  height: 4rem !important;
  border-radius: 8px !important;
  border: 1px solid #e7e7e7 !important;
} */

.edit-field-title {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	margin-top: -1rem;
	letter-spacing: normal;
	text-align: left;
	color: #656565;
}

.edit-input-title {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	color: #656565;

	position: absolute;
	top: 5px;
	left: 30px;
	z-index: 1;
}

.edit-field::placeholder {
	color: #282725;
}

.dropdown-toggle::after {
	display: none !important;
}

.edit-profile-dropdown-arrow {
	width: 1.5rem;
	position: absolute !important;
	right: 0;
	top: 0;
	margin: auto;
	bottom: 0;
}

.dropdown-menu.show {
	background: #ededed;
}

.edit-profile-save-btn {
	position: absolute;
	margin-top: 1%;
	display: flex;
	font-weight: 400;
	width: 100%;
	height: 3.5rem;
	color: #000000;
	justify-content: center;
	align-items: center;
	@extend .enable-click;
	user-select: none;
	background-color: #fbd731;
	border: 1px solid transparent;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.confirm-modal {
	position: fixed !important;
	width: 50% !important;
	padding: 0 !important;
	bottom: 50%;
	left: 0;
	margin-left: auto;
	right: 0;
	margin-right: auto;
	z-index: 2;
	opacity: 0.95;
	@extend .default-border-radius;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
	/* color: white; */
	/* background-color: #282725; */
	background-color: white;
}

@media only screen and (max-width: 576px) {
	.confirm-modal {
		width: 100% !important;
	}
}

.confirm-modal-text {
	font-family: Poppins;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.37;
	letter-spacing: normal;
	text-align: left;
	/* color: #ffffff; */
}

#confirm-modal-password {
	border: solid 1px rgba(101, 101, 101, 0.3) !important;
}

#confirm-modal-password:focus {
	outline: none !important;
	/* border: 1px solid white; */
	/* box-shadow: 0 0 5px white; */
}

#confirm-modal-password::placeholder {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.48;
	letter-spacing: normal;
	text-align: left;
	/* color: #ededed; */
}

.confirm-modal-button {
	position: absolute;
	height: 3.5rem;
	color: #0f0f0f;
	background: transparent;
	border: solid 1px rgba(101, 101, 101, 0.3);
}

.confirm-modal-button-disabled {
	color: #282725;
	opacity: 0.5;
}

.confirm-modal-button-confirm {
	color: #00cc69;
}

#payment-modal {
	position: fixed !important;
	height: 100%;
	width: 80% !important;
	padding: 2rem !important;
	/* bottom: 50%; */
	left: 0;
	margin-left: auto;
	right: 0;
	margin-right: auto;
	z-index: 2;
	opacity: 0.95;
	@extend .default-border-radius;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	overflow: auto;
}

@media only screen and (max-width: 576px) {
	#payment-modal {
		width: 100% !important;
	}
}

#gamedetails-modal {
	position: fixed !important;
	top: 5%;
	bottom: 5%;
	left: 10%;
	right: 10%;
	margin-left: auto;
	margin-right: auto;
	z-index: 2;
	opacity: 0.95;
	@extend .default-border-radius;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
	background-color: white;
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	justify-content: initial;
}

#bugspotting-screenshot-modal {
	@extend #gamedetails-modal;
	top: initial;
	bottom: initial;
	left: 20%;
	right: 20%;
}

@media only screen and (max-width: 576px) {
	#gamedetails-modal {
		left: 2%;
		right: 2%;
	}
}

@media only screen and (max-width: 767px) {
	#bugspotting-screenshot-modal {
		left: 2%;
		right: 2%;
	}
}

.gamedetails-text-outer {
	height: 100%;
	overflow: scroll;
	margin-bottom: 1rem;
	/* border: 1px solid #ededed; */
	/* padding: 2rem;
  padding-top: 2rem; */
	/* padding-bottom: 4rem; */
	/* border-radius: 8px 8px 0 0; */
}

#gamedetails-image {
	width: 80%;
	@extend .default-border-radius;
	z-index: 0;
}

.gamedetails-image-container {
	/* position: absolute; */
	/* margin-top: 10vh; */
	/* right: 0;
  margin-right: auto;
  left: 0;
  margin-left: auto; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	margin-left: 2rem !important;
}

#creditcard {
	width: 50%;
	align-self: center;
	margin: 0px 50px 50px 50px;
	padding: 1rem;
	border: 1px solid grey;
	@extend .default-border-radius;
}

#creditcardtext {
	width: 80%;
	align-self: center;
	padding: 1rem;
}

@media only screen and (max-width: 576px) {
	#creditcardtext {
		width: 100%;
	}
}

.achievementtitle {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.37;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.achievementsubtitle {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}


.achievement-card {
	font-family: Poppins;
	font-size: 13px !important;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	display: flex;
	width: 100%;
	color: rgba(40, 39, 37, 0.5);
	/* margin-top: 0.625rem; */
	margin-bottom: 0.625rem;
	text-align: left;
	vertical-align: middle;
	@extend .enable-click;
	background-color: transparent;
	border-radius: 8px !important;
	//border: solid 1px #707070;
	font-size: 1rem;
	line-height: 1.5;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #ffffff;

	&-border-faded {
		@extend .achievement-card;
		border: none;
		border-radius: 10px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
		/* margin-top: 0.5rem; */
		margin-bottom: 0.5rem;
	}

	&-wow {
		@extend .achievement-card;
		//box-shadow: 0 3px 6px 0 #fbd63146;
	}
}

@media only screen and (min-width: 1024px) {
	.achievement-card {
		width: 75%;
	}
}

.achievement-wow {
	//box-shadow: 0px -100px 50px -40px inset #ffe11b7e; // Makes it yellow and glowy
	box-shadow: 0px -60px 50px -96px inset #ffe11ba1; // Adds a small glow on the bottom
	border-color: #bca405 !important;
	border-width: 1px !important;
}

.achievement-first-part {
	width: 18%;
	padding: 0;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 0.75rem;

	border-radius: 8px 0 0 8px;
	border-top: solid 1px #707070;
	border-bottom: solid 1px #707070;
	border-left: solid 1px #707070;

	&-wow {
		@extend .achievement-first-part;
		@extend .achievement-wow;
	}
}

.achievement-second-part {
	width: 64%;
	padding: 0;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;

	border-radius: 0 0 0 0;
	border-top: solid 1px #707070;
	border-bottom: solid 1px #707070;

	&-wow {
		@extend .achievement-second-part;
		@extend .achievement-wow;
	}
}

.achievement-third-part {
	width: 18%;
	padding: 0;

	border-radius: 0 8px 8px 0;
	border-top: solid 1px #707070;
	border-bottom: solid 1px #707070;
	border-right: solid 1px #707070;

	&-wow {
		@extend .achievement-third-part;
		//@extend .achievement-wow;
	}
}

.achievement-first-part-upper {
	height: 36px;
}

.achievement-second-part-upper {
	height: 36px;
}

.achievement-third-part-upper {
	height: 60px; // ^ above + 12 + 12 (add the padding)
}

.achievement-second-part-bottom {
	padding-top: 18px;
}

.achievement-third-part-bottom {
	padding-top: 8px;
	padding-bottom: 8px;

	display: flex;
	justify-content: center;
}

.achievement-title {
	font-family: Poppins;
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.37;
	letter-spacing: normal;
	text-align: left;
	color: #000000;

	width: 60%;
}

.achievement-description {
	font-family: Poppins;
	font-size: 12px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: left;
	color: #000000;
}

.achievement-info-part {
	width: 40%;
	display: flex;
}

.achievement-reward-part {
	//width: 15%;
	width: 37.5%;
	padding-right: 15px;
}

.achievement-completed-part {
	//width: 25%;
	width: 62.5%;
	padding-left: 15px;
}

.achievement-reward-value {
	color: #00cc69;
	text-align: right;

	font-family: Poppins;
	font-size: 16px;
	margin-bottom: 0%;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	line-height: 1.4;
}

.achievement-reward-unit {
	color: #656565;
	text-align: right;

	font-family: Poppins;
	font-size: 9px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	line-height: 1.4;
}

.achievement-completed-percentage {
	color: #656565;
	text-align: left;

	font-weight: 400;
	font-size: 20px;
	line-height: 1;
}

.achievement-completed-undertext {
	color: #656565;
	text-align: left;

	font-weight: 300;
	font-size: 9px;
	line-height: 1.4;
}

.achievement-completed-date {
	color: #656565;
	text-align: center;
}

.achievement-XXcompleted {
	height: 100%;
	min-height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: 700;
	font-size: 0.8vw;
}

.achievement-completed {
	@extend .achievement-XXcompleted;
	background-color: #00cc69;
}

.achievement-uncompleted {
	@extend .achievement-XXcompleted;
	background-color: #d7d5d5;
	font-size: 0.7vw;

}

.achievement {
	&-filter {
		&-container {
			display: flex;
			margin-top: 8px;
		}

		&-element {
			max-width: 25%;
			width: 25%;
			padding-left: 1rem;
			height: 64px;
		}

		&-unlocked {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}


.border-radius-right-7 {
	border-radius: 0 7px 7px 0;
}

.border-radius-right-top-7 {
	border-radius: 0 7px 0px 0;
}

#coefficientinput {
	font-family: Poppins;
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.48;
	letter-spacing: normal;
	display: inline-block;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 100%;
	height: auto !important;
	color: #000000;
	text-align: left;
	vertical-align: middle;
	/* @extend .enable-click; */
	/* background-color: transparent !important; */
	background-color: transparent;
	border: 1px solid #e3e3e3 !important;
	padding: 1.375rem 1.75rem !important;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem !important;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

	&.custom-no-border {
		border: 1px transparent !important;
	}
}

#numberinput {
	@extend #coefficientinput;
	background-color: white;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.add-achievement-toggle-btn {
	position: absolute;
	margin-top: 1%;
	display: flex;
	font-weight: 400;
	width: 100%;
	height: 3.5rem;
	color: #000000;
	justify-content: center;
	align-items: center;
	@extend .enable-click;
	border: 1px solid #ededed;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.main-btn {
	display: flex;
	font-weight: 500;
	width: auto;
	height: 3.5rem;
	margin: 1rem auto;
	padding: 0 5rem;
	color: #000000;
	background-color: #fbd731;
	justify-content: center;
	align-items: center;
	@extend .enable-click;
	font-size: 1rem;
	line-height: 1.5;
	border: 0;
	border-radius: 0.5rem;
}

#uploadapk-modal {
	position: fixed !important;
	width: 50% !important;
	padding: 0 !important;
	bottom: 30%;
	left: 0;
	margin-left: auto;
	right: 0;
	margin-right: auto;
	z-index: 2;
	opacity: 0.95;
	@extend .default-border-radius;
	color: white;
	box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
	background-color: #282725;
}

@media only screen and (max-width: 576px) {
	#uploadapk-modal {
		width: 100% !important;
	}
}

.stake-yellow {
	font-family: Poppins;
	font-size: 36px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: -1.8px;
	text-align: left;
	color: #fcd902;
}

/*
.store-button-image {
	width: 110px;
	height: 40px;
}
*/

.gamedetails-store-button {
	float: left;
	width: 2.5rem;
	margin-top: 10px;
	margin-left: 10px;

	&.horizontal {
		width: 7rem;
	}
}

.gamedetails-store-image {
	width: 100%;
	height: 2.5rem;
}

.details-modal-inner {
	max-width: 30%
}

@media only screen and (max-width: 991px) {
	/*
	.gamedetails-store-button {
		float: none;
		display: inline-block;
	}
	*/

	.details-modal-inner {
		max-width: 100%;
	}

	.details-modal-image {
		display: flex;
		flex: 0 0 100%;
		margin: auto;
		/* max-width: 50% !important; */
		max-height: 50vh !important;

		&.custom-image-height {
			max-height: 30vh !important;
		}
	}
}

/* Modal header position */
.modal-header-position {
	position: absolute;

	top: 5px;
	right: 10px;

	z-index: 2;

	/* width: 100%; */
	border: none !important;

	&.dark {
		.close {
			color: #fff;
			opacity: 1;
		}
	}
}

.modal-title-big {
	font-size: 2rem;
	word-wrap: break-word;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

/*
@media only screen and (max-width: 576px) {
	.modal-title-big {
		font-size: 2rem;
	}
}
*/

.send-to-input-box {
	border: 2px solid lightgrey;
	border-radius: 5px;
}

.activity-statistics-container {
	max-height: 85vh;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 12px;
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	/*
	.activity-statistics-container {
		max-height: initial;
		overflow-y: initial;
	}
	*/

	.achievements-ach-card {
		margin-left: 10px !important;
		margin-right: 10px !important;
	}

	.lastplayed-slider {
		max-height: 85vh;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.flex {
	display: flex;
}

.items-end {
	align-items: flex-end;
}

.content-end {
	justify-content: flex-end;
}

.flex-top {
	display: flex;
	align-items: flex-start;
}

.flex-top-right {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
}

.flex-top-left {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.flex-bottom-right {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.flex-bottom-left {
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
}

.icon-32px {
	/* width: 32px; */
	height: 32px;
}

.icon-32px-force {
	width: auto !important;
	height: 32px !important;
}


.social-media-icon {
	@extend .enable-click;

	font-size: 2rem !important;

	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.game-state-banner {
	position: absolute;
	top: 0px;
	left: -1px;
	z-index: 2;
	max-width: 200px;
	@extend .default-border-radius;
}

.mini-game-state-banner {
	position: absolute;
	top: 0px;
	left: -1px;
	z-index: 2;
	max-width: 200px;
	@extend .default-border-radius;
}

.game-state-banner-modal {
	position: absolute;
	top: -1px;
	left: -1px;
	z-index: 2;
	max-width: 175px;
	/* @extend .default-border-radius; */
}

.slide {
	background-color: initial !important;
}

.servicecard {
	@extend .achievementcard-border-faded;
	padding: 0 !important;
	overflow: hidden !important;
	background-color: #ededed;
}

.servicecard-dark {
	@extend .servicecard;
	background-color: #282725;
}

.servicecard-title {
	@extend .achievementtitle;
	font-size: 1.25rem !important;
}

.servicecard-title-dark {
	@extend .servicecard-title;
	color: white;
}

.servicecard-description {
	@extend .achievementtitle;
	font-weight: initial;
}

.servicecard-description-dark {
	@extend .servicecard-description;
	color: white;
}

.servicecard-price {
	@extend .achievementtitle;
	font-size: 2rem !important;
	font-weight: initial !important;
}

.servicecard-price-section {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fcd902 !important;
}

.servicecard-vertical-separator {
	border-right: 1px solid rgba(0, 0, 0, 0.25);
}

.top-games-list-card {
	min-height: 5rem;
	margin-bottom: 1rem;
	color: #282725;
	background-color: white;
	border: 1px solid #e3e3e3;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.5rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.top-games-details-card {
	width: 100%;
	max-width: 500px;

	display: inline-block;

	background-size: cover;
	background-position: center;

	text-align: left;
}

.gamedev-marketplace-center-subtitle {
	padding-left: 2rem !important;

	@media only screen and (max-width: 575px) {
		.subtitle {
			max-width: 50%;
			margin: auto;
		}
	}
}

.games {
	font-size: 24px !important;
	padding-left: 1rem !important;
}

#no-padding {
	padding: 0 !important;
}

.ml-8px {
	margin-left: 8px;
}

.t_registerTitle {
	margin-top: 1rem;
	margin-bottom: 1.5rem;
}

.smsstyles {
	transition: height 0.5s ease;
	overflow: hidden;
}

.smshidden {
	height: 0;
}

.smsdisplay {
	height: 40px;
}

.troubledisplay {
	height: 80px;
}

.bold-link a {
	font-weight: bold;
}

.loading {
	width: 120px;
	height: 20px;
	-webkit-mask: linear-gradient(90deg, #000 70%, #0000 0) left/20% 100%;
	background:
		linear-gradient(#000 0 0) left -25% top 0 /20% 100% no-repeat #ddd;
	animation: l7 1s infinite steps(6);

	@keyframes l7 {
		100% {
			background-position: right -25% top 0
		}
	}
}

@keyframes loading_spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes loading_spin_backforth {
	0% {
		transform: rotate(0deg);
	}

	25% {
		transform: rotate(60deg);
	}

	60% {
		transform: rotate(-30deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes floaty {
	0% {
		transform: translateY(0px);
	}

	30% {
		transform: translateY(-4px);
	}

	70% {
		transform: translateY(4px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes wavy {
	0% {
		transform: rotate(1deg) translateY(2px);
	}

	35% {
		transform: rotate(-3deg) translateY(0px);
	}

	70% {
		transform: rotate(3deg) translateY(-2px);
	}

	100% {
		transform: rotate(1deg) translateY(2px);
	}
}

.loading-spinning-image {
	animation: wavy 2s linear infinite;
}

.hover-underline:hover {
	text-decoration: underline;
}

.game-share-btn {
	font-family: Poppins;
	font-size: 15px;
	font-weight: 500;
	line-height: 1.48;
	text-align: center;
	margin: 0;
	width: 100px;
	height: 40px;
	user-select: none;
	cursor: pointer;
	//background-color: #fcd902;
	border: 1px solid #fcd902 !important;
	border-radius: 0.5rem !important;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	&:hover {
		transition: background-color .25s ease-in;
		background-color: #fcdb0210;
	}
}

.videoContainer {
	width: 100%;
	height: 80vh;
	position: relative;
	z-index: 0;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&::before {
		content: '';
		z-index: 10;
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		background:
			linear-gradient(90deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.1) 100%),
			linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.4) 50%, rgba(0, 0, 0, 0.1) 100%);
	}
}

.featured-game {
	background-color: #000 !important;
	color: #fff;
	margin-top: -80vh;

	&>* {
		position: relative;
		z-index: 2;
	}

	.featured-title {
		color: #fbd731;
		font-size: 1.3rem;
		margin-bottom: 2rem;
	}

	.featured-game-title {
		font-size: 3rem;
		font-weight: 500;
		line-height: 1.2;
	}

	.featured-game-tag {
		font-size: 1.1rem;
	}

	.featured-button {
		width: 160px;
		height: 3rem;
		font-size: 1rem;
		padding: 0;
		margin: 0.5rem 0 2rem;
	}

	.featured-game-rate {
		color: #9C9C9C;
		font-weight: 600;
		font-size: 1.1rem;
	}

	.featured-rating-number {
		font-size: 1.8rem;
		font-weight: 300;
		color: #fbd731;
		margin-right: 10px;
	}
}

@media only screen and (max-width: 991px) {
	.featured-game {
		.featured-title {
			font-size: 1.2rem;
			margin-bottom: 2rem;
		}

		.featured-game-title {
			font-size: 2rem;
			font-weight: 600;
		}

		.featured-rating-number {
			font-size: 1.4rem;
		}
	}
}

.game-list {
	background-color: #000 !important;
	color: #ffffff;

	&>* {
		position: relative;
		z-index: 2;
	}

	h1 {
		z-index: 1;
		font-size: 2rem;
		font-weight: normal;
		color: #fbd731;
	}

	.games-platform {
		z-index: 1;
		width: 100%;
		display: flex;
		flex-direction: column;

		.games {
			user-select: none;
			display: flex;
			flex-direction: row;

			margin: 0 -2rem;

			white-space: nowrap;
			overflow-x: scroll;
			-webkit-overflow-scrolling: touch;

			&::-webkit-scrollbar {
				display: none;
			}

			.game-card {
				display: flex;
				gap: 0;
				align-items: center;

				.game-number {
					color: #927d1f;
					font-size: 140px;
					font-weight: 800;
					margin-right: -20px;
				}

				.game-image {
					width: 180px;
					box-shadow: 0px 0 12px #c0c0c0;
					border-radius: 10px;
				}

				&:hover {
					.game-number {
						color: #fbd731 !important;
					}

					.game-image {
						box-shadow: 0px 0 15px #f3f3f3;
					}
				}

				&.active {
					.game-image {
						box-shadow: 0px 0 12px #fbd731;
					}

					&:hover .game-image {
						box-shadow: 0px 0 15px #fbd731;
					}
				}
			}
		}

		.g-mobile {
			.games .game-card {
				.game-number {
					color: #00000000;
					-webkit-text-stroke-width: 3px;
					-webkit-text-stroke-color: #927d1f;
				}

				&:hover .game-number {
					-webkit-text-stroke-width: 0;
				}
			}

		}
	}
}

@media only screen and (max-width: 991px) {
	.game-list {
		.games-platform {
			flex-direction: column-reverse;

			.g-desktop {
				.games .game-card .game-number {
					color: #00000000;
					-webkit-text-stroke-width: 3px;
					-webkit-text-stroke-color: #927d1f;
				}
			}

			.g-mobile {
				.games .game-card .game-number {
					color: #927d1f;
					-webkit-text-stroke-width: 0;
				}
			}
		}
	}
}

.game-challenge {
	border: 2px solid #fbd731;
	border-radius: 8px;
	margin: 8px 0;
	padding: 16px 16px;
	display: flex;
	align-items: center;
	gap: 32px;
	font-size: 14px;
	user-select: none;
	cursor: pointer;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

	.challenge-description {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		min-width: 0;
		margin: 0;

		&.expanded {
			white-space: initial;
			text-overflow: initial;
		}
	}

	.challenge-completed {
		flex-shrink: 0;

		align-self: stretch;
		vertical-align: middle;
		width: 25%;
		margin: -16px -16px -16px 0;
		border-radius: 0 6px 6px 0;

		font-size: 1rem;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		color: #fff;
		background-color: #00cc69;

		display: flex;
		align-items: center;

		span {
			margin: auto;
		}

		.completed-date {
			font-size: 0.8rem;
		}

		&.challenge-disabled {
			background-color: #d7d5d5;
		}
	}
}

@media only screen and (max-width: 767px) {
	.game-challenge {
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;

		.icon-32px-force {
			height: 60px !important;
		}

		.editgamebtn {
			padding: 0.5rem 1.5rem !important;
		}

		.challenge-description {
			white-space: initial;
		}

		.challenge-completed {
			flex-basis: auto;

			border-radius: 0 0 6px 6px;
			margin: 16px -16px -16px;
			width: auto;
			padding: 16px 0;

			.completed-txt {
				font-size: 1.3rem;
			}
		}

		.achievement-reward-value {
			font-size: 1.3rem;
		}

		.achievement-reward-unit {
			font-size: 0.8rem;
		}
	}
}

.shadow-tooltip {
	box-shadow: 0px 2px 15px;
}

.airdrop-banner {
	font-size: 12px;
}

.airdrop-popup {
	.airdrop-close {
		position: absolute;
		right: 1rem;
		cursor: pointer;

		img {
			height: 2rem;
		}
	}

	.airdrop-header {
		background-color: #fbd731;
		margin: 0 auto;
		padding: 1.5rem 3rem 1rem;
		text-align: center;
		h3 {
			font-weight: 400;
		}
	}

	.airdrop-rewards {
		background-color: #282725;
		text-align: center;
		padding: 1rem 0;

		.airdrop-total {
			color: #fcd902;
			font-size: 2.5rem;

			span {
				font-size: 1rem;
			}
		}

		.airdrop-info {
			color: #fff;
			font-size: 1.5rem;
		}
	}

	.airdrop-leaderboard {
		padding: 1rem 0;
		color: #ffffff;
		background-color: #1869fe;
		cursor: pointer;

		span {
			margin-left: 0.5rem;
		}
	}

	.airdrop-socials {
		margin: 1rem;

		a {
			@extend .enable-click;

			font-size: 2.5rem;

			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
	}
}

.email-warning {
	font-size: 0.8rem;
	line-height: 1.1;
	color: #f2544c;
	margin-top: -0.8rem;
	margin-bottom: 10px;
}



// MARK: WALLET Balance
.wallet-balance-container {
	padding:4px 0 0px 0;
}

.wallet-balance-border {
	margin-bottom: 8px;
	padding-bottom: 7px;
	border-bottom: 1px solid $qudo-yellow;
}

.wallet-balance-title {
	color: $qudo-medium-gray;
	font-size: 18px;
}

.wallet-balance-balance {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
	align-content: center;
	color: $qudo-dark-gray;
	line-height: 1;
	gap: 10px;
	height: 69px;
}

.wallet-username-text {
	font-size: 14px;
	color: $qudo-dark-gray; 
	font-weight: 400;
	letter-spacing: 2px !important;
}

.wallet-username-text-small {
	font-size: 12px;
	color: $qudo-dark-gray; 
	font-weight: 400;
	letter-spacing: 1.5px !important;
}

// MARK: CLICK Animation
@keyframes clickAnimation {
	0% {
	  	transform: scale(1);
	}
	50% {
	  	transform: scale(0.92);
	}
	100% {
	  	transform: scale(1);
	}
}
  
.click-animation {
	animation: clickAnimation 0.2s ease-in-out;
}
.copy-icon {
	transition: transform 0.2s ease-in-out;
}
.copy-icon:hover {
	transform: scale(1.08);
}

.click-hover-animation {
    transition: transform 0.2s ease-in-out;
}

.click-hover-animation:hover {
    animation: clickAnimation 0.2s ease-in-out;
}



@keyframes bounce {
	50% {
	  	transform: rotate(5deg) translate(20px, -50px);
	}
	to {
		transform: scale(0.9) rotate(10deg) translate(50px, -80px);
		opacity: 0;
	}
}
.bounce-animation {
	animation: bounce 0.2s 1 linear;
}
  

// MARK: network-selector
.network-selector {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid $qudo-light-gray;
	padding: 8px 0px 4px;
}

.network-selector label{
	margin: 0;
}  

.network-selector input[type="radio"] {
	appearance: none; /* Remove default radio button styling */
	-webkit-appearance: none; /* For some older browsers */
	width: 16px;
	height: 16px;
	border: 1px solid $qudo-light-gray;
	border-radius: 0%; /* 50% = round - 0% = square */
	margin-right: 0.5em;
	outline: none;
	cursor: pointer;
	position: relative; /* For the inner dot */
}
  
/* Style for the checked state (inner dot) */
.network-selector input[type="radio"]:checked {
	border-color: $qudo-dark-gray; /* Dark border when checked */
}
  
.network-selector input[type="radio"]:checked::before {
	content: '';
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); /* Center the dot */
	width: 12px; /* Size of the inner dot */
	height: 12px;
	background-color: $qudo-dark-gray; /* Black dot */
	border-radius: 0%; /* 50% = round - 0% = square */
}

.network-disabled {
	color: #d6d6d6;
	border-color: #d6d6d6;
	cursor: not-allowed;
}
.network-disabled input[type="radio"]{
	cursor: not-allowed;
}

/*
.network-item:hover{
  	cursor: pointer;
  	opacity: 50%;
}
*/


.wallet-icon-container {
	position: relative;
	width: 32px; // adjust the size as needed
	height: 32px;
	margin: 4px 0 8px 0; // because to make up for the values below, on the icon
}
	
.wallet-icon {
	position: absolute;
	top:  -6px;
	left: -6px;
	width:  44px;
	height: 44px;
	z-index: 1; // ensure the wallet icon is on top
}
	
.wallet-icon-chains {
	position: absolute;
	top:  -10px;
	left: -10px;
	width: 100%;
	//height: 0; // initial height is 0, will be triggered on hover
	//transition: height 0.3s ease-in-out; // smooth transition effect

	width: 	52px;
	height: 52px;
	scale: 0;
	transition: scale 0.3s cubic-bezier(0.18, 0.43, 0.39, 0.87);
}
	
.wallet-icon-container {
	&:hover {
		.wallet-icon-chains {
			// height: 100%; // expand the chains icon to full height on hover, looks like it "opens"
			scale: 1;
		}
		.wallet-icon {
			transform: translateX(-0px); // optional: slightly move the wallet icon to the left on hover
		}
	}
}